.cover {
    padding-top: var(--gui-gutter-xl);
    padding-bottom: var(--gui-gutter-xl);
    background-color: var(--gui-background-color-default);
    border-radius: var(--gui-border-radius);
    border-width: 1px;
    border-style: solid;
    border-color: var(--gui-color-border);
    transition: all var(--gui-transition-duration-very-fast) linear;
}

.icon {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--gui-gutter-md);
    text-align: center;
}

.title {
    margin-bottom: 0;
}

.title h2 {
    font-weight: 500;
}

.amount {
    margin-top: var(--gui-gutter-base);
    margin-bottom: 0;
}