.balance {
    display: flex;
    margin-bottom: var(--gui-gutter-base);
    gap: var(--gui-gutter-md);
    align-items: center;
}

.month {
    font-size: var(--gui-font-size-large);
    margin-bottom: 0;
    opacity: .6;
}

.amount {
    margin-bottom: 0;
}

.progress {
    margin-top: var(--gui-gutter-base);
}