.header {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    max-width: 100vw;
    padding-top: var(--gui-gutter-base);
}

.container {
    position: relative;
    display: grid;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    row-gap: var(--gui-gutter-sm);
}

.container button {
    display: flex;
    align-items: center;
    gap: var(--gui-gutter-md);
    text-decoration: none;
    border-color: var(--gui-color-primary);
    background-color: transparent;
    color: var(--gui-color-primary);
    font-weight: 500;
}

.container button:not(:disabled):hover {
    color: var(--gui-color-primary);
    background-color: transparent;
}

.container button svg {
    fill: var(--gui-color-primary);
}

@media (max-width: 960px) {
    .container {
        padding-left: var(--gui-gutter-base);
        padding-right: var(--gui-gutter-base);
    }
}

.logo {
    font-size: 24px;
}

.menu {
    grid-column: 1 / span 2;
    display: flex;
    align-items: center;
    gap: var(--gui-gutter-lg);
    padding: var(--gui-gutter-base);
    overflow-y: scroll;
    max-width: 100vw;
    background-color: var(--gui-background-color-default);
    border-radius: var(--gui-border-radius);
    backdrop-filter: blur(8px);
}

.avatar {
    text-align: left;
}