/* TODO: mutualize in a /component/icon */
.entry {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 34px;
    height: 34px;
    border-radius: 100%;
    background-color: rgba(3, 166, 136, .2);
    border-color: rgba(3, 166, 136, .4);
}

.background {
    position: absolute;
    border-radius: 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .2;
    background-color: rgba(3, 166, 136, .2);

}

.icon {
    z-index: 2;
    color: rgba(3, 166, 136, 1);
}
