.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: var(--gui-gutter-4xl);
}