.link {
    color: var(--gui-color-white);
    text-decoration: none;
    transition: color var(--gui-transition-duration-very-fast) ease-in-out;
    display: inline-block;
}

.link:hover {
    color: var(--gui-color-primary-active-darken);
}

.link:hover svg {
    fill: var(--gui-color-primary-active-darken);
}

.link.active {
    color: var(--gui-color-primary);
}

.link.active svg {
    fill: var(--gui-color-primary);
}