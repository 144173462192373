.container {
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content {
    margin-bottom: var(--gui-gutter-xl);
}

.text {
    opacity: .6;
}